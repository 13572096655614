import React from 'react';
import { Box, Flex, Heading, Text, Image, VStack, HStack, Button, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <Box p={4} maxW="1200px" mx="auto">
      {/* Hero Section */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
        py={10}
      >
        <Box flex="1" mr={{ base: 0, md: 6 }} mb={{ base: 6, md: 0 }}>
          <Heading as="h1" size="2xl" mb={4}>
            Welcome to Avocadoed
          </Heading>
          <Text fontSize="lg" color="gray.600">
            At Avocadoed, we believe in delivering the freshest, creamiest, and most delicious avocados directly to your table. Our mission is to make your love for avocados a delightful and hassle-free experience.
          </Text>
        </Box>
        <Image
          src="https://cdn.pixabay.com/photo/2017/03/04/12/10/avocado-2115922_1280.jpg" // Replace this with your image path
          alt="Fresh avocados on a table"
          borderRadius="md"
          boxShadow="md"
          flex="1"
          maxH="300px"
          objectFit="cover"
        />
      </Flex>

      <Divider my={10} />

      {/* Our Mission Section */}
      <Flex direction={{ base: 'column', md: 'row' }} align="center" py={10}>
        <Image
          src="https://cdn.pixabay.com/photo/2024/08/19/00/14/avocado-8979119_1280.jpg" // Replace this with your image path
          alt="Team picking avocados"
          borderRadius="md"
          boxShadow="md"
          flex="1"
          maxH="300px"
          objectFit="cover"
          mr={{ base: 0, md: 6 }}
          mb={{ base: 6, md: 0 }}
        />
        <Box flex="1">
          <Heading as="h2" size="xl" mb={4}>
            Our Mission
          </Heading>
          <Text fontSize="md" color="gray.600">
            We are dedicated to sourcing sustainable and ethically grown avocados. Our farmers take pride in cultivating each avocado with care, ensuring they are ripe, nutritious, and full of flavor. By prioritizing eco-friendly practices, we aim to reduce waste and support the environment.
          </Text>
        </Box>
      </Flex>

      <Divider my={10} />

      {/* Why Choose Us Section */}
      <VStack spacing={8} py={10} textAlign="center">
        <Heading as="h2" size="xl">
          Why Choose Avocadoed?
        </Heading>
        <Text fontSize="lg" color="gray.600" maxW="800px">
          From farm to fork, we ensure the best quality avocados are delivered to your doorstep. Here’s what makes us stand out:
        </Text>
        <HStack spacing={10} wrap="wrap" justify="center">
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2016/11/23/00/21/avocado-1851422_1280.jpg" // Replace this with your image path
              alt="Freshness guaranteed"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"

            />
            <Heading as="h3" size="md" mb={2}>
              Freshness Guaranteed
            </Heading>
            <Text color="gray.600">
              Our avocados are handpicked at the peak of their ripeness to ensure unmatched quality.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/03/27/08/45/current-4972878_1280.jpg" // Replace this with your image path
              alt="Sustainable practices"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"
            />
            <Heading as="h3" size="md" mb={2}>
              Sustainable Practices
            </Heading>
            <Text color="gray.600">
              We prioritize eco-friendly farming and packaging methods.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/12/06/22/51/ship-5810249_1280.jpg" // Replace this with your image path
              alt="Delivered to your door"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"

            />
            <Heading as="h3" size="md" mb={2}>
              Delivered to Your Door
            </Heading>
            <Text color="gray.600">
              Hassle-free delivery that brings the best avocados straight to your home.
            </Text>
          </Box>
        </HStack>
      </VStack>

      <Divider my={10} />

      {/* Call to Action */}
      <Box textAlign="center" py={10}>
        <Heading as="h2" size="xl" mb={4}>
          Join the Avocado Revolution
        </Heading>
        <Text fontSize="lg" color="gray.600" maxW="800px" mx="auto" mb={6}>
          Whether you're an avocado aficionado or just discovering the joys of this superfruit, Avocadoed is here to elevate your experience. Join our community and taste the difference.
        </Text>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <Box
            as="button"
            colorScheme="green"
            size="lg"
            px={6}
            py={3}
            borderRadius="md"
            bg="green.500"
            color="white"
            _hover={{ bg: "green.600" }}
          >
            Contact Us
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default About;
