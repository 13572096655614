import React from 'react';
import { Box, HStack, Image, Heading, Text, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const FAQ = () => {
  return (
    <Box p={4} maxW="800px" mx="auto">
      <br></br><br></br><br></br>
      {/* Header */}
      <Heading as="h1" size="2xl" mb={6} textAlign="center">
        Frequently Asked Questions
      </Heading>

      <Text fontSize="lg" color="gray.600" mb={10} textAlign="center">
        Got questions? We've got answers! If you don't see your question here, feel free to reach out to us on our Contact page.
      </Text>


      {/* FAQ Section */}
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                What is Avocadoed?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Avocadoed is your trusted source for the freshest and most delicious avocados, delivered straight to your door. We prioritize sustainability and quality to provide you with a premium experience.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Where do your avocados come from?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Our avocados are sourced from sustainable farms that prioritize eco-friendly practices. We work closely with farmers to ensure ethical and responsible cultivation.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                How do you ensure the freshness of your avocados?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We handpick our avocados at their peak ripeness and carefully package them to preserve their quality during transit. Our delivery system ensures they arrive fresh and ready to enjoy.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Do you offer subscriptions?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes! We offer flexible subscription plans to ensure you never run out of avocados. Choose the frequency that works best for you and enjoy hassle-free deliveries.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                What should I do if I receive a damaged avocado?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We strive for perfection, but if you ever receive a damaged avocado, please contact our customer service team. We'll gladly replace it or offer a refund.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <br></br><br></br>

      <br></br><br></br>
      
      {/* Why Choose Us Section */}
      <VStack spacing={8} py={10} textAlign="center">
        <Heading as="h2" size="xl">
          Why Choose Avocadoed?
        </Heading>
        <Text fontSize="lg" color="gray.600" maxW="800px">
          From farm to fork, we ensure the best quality avocados are delivered to your doorstep. Here’s what makes us stand out:
        </Text>
        <HStack spacing={10} wrap="wrap" justify="center">
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2016/11/23/00/21/avocado-1851422_1280.jpg" // Replace this with your image path
              alt="Freshness guaranteed"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"

            />
            <Heading as="h3" size="md" mb={2}>
              Freshness Guaranteed
            </Heading>
            <Text color="gray.600">
              Our avocados are handpicked at the peak of their ripeness to ensure unmatched quality.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/03/27/08/45/current-4972878_1280.jpg" // Replace this with your image path
              alt="Sustainable practices"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"
            />
            <Heading as="h3" size="md" mb={2}>
              Sustainable Practices
            </Heading>
            <Text color="gray.600">
              We prioritize eco-friendly farming and packaging methods.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/12/06/22/51/ship-5810249_1280.jpg" // Replace this with your image path
              alt="Delivered to your door"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"

            />
            <Heading as="h3" size="md" mb={2}>
              Delivered to Your Door
            </Heading>
            <Text color="gray.600">
              Hassle-free delivery that brings the best avocados straight to your home.
            </Text>
          </Box>
        </HStack>
      </VStack>

      <Divider my={10} />

      {/* Call to Action */}
      <Box textAlign="center" py={10}>
        <Heading as="h2" size="xl" mb={4}>
          Join the Avocado Revolution
        </Heading>
        <Text fontSize="lg" color="gray.600" maxW="800px" mx="auto" mb={6}>
          Whether you're an avocado aficionado or just discovering the joys of this superfruit, Avocadoed is here to elevate your experience. Join our community and taste the difference.
        </Text>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <Box
            as="button"
            colorScheme="green"
            size="lg"
            px={6}
            py={3}
            borderRadius="md"
            bg="green.500"
            color="white"
            _hover={{ bg: "green.600" }}
          >
            Contact Us
          </Box>
        </Link>
      </Box>
    </Box>
    
  );
};

export default FAQ;
