import React from "react";
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, Heading } from "@chakra-ui/react";

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted");
  };

  return (
    <Box mb={"500px"} maxW="800px" mx="auto" p={4}>
      <Heading as="h2" size="xl" mb={4}>
        Contact Us
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="name">Full Name</FormLabel>
            <Input id="name" placeholder="Your name" />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <Input id="email" type="email" placeholder="Your email" />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="message">Message</FormLabel>
            <Textarea id="message" placeholder="Your message" />
          </FormControl>
          <Button type="submit" colorScheme="teal" width="full">
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Contact;
