import React from 'react';
import { Box, Flex, Heading, Text, Image, VStack, HStack, Button, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <Box p={4} maxW="1200px" mx="auto">
      {/* Hero Section */}
      <Heading
        mt={"80px"}
          textAlign={"center"}
          as="h1"
          fontSize={{ base: "3xl", sm: "6xl", md: "7xl" }}
          color={'gray.800'}
          fontWeight="bold"
        >
          Avocadoed
        </Heading>
        <Text
          textAlign={"center"}
          fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
          color={'green.500'}
          mb={"80px"}
        >
          <em>Your Source for Fresh Avocados</em>
        </Text>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
        py={10}
        bg="green.50"
        borderRadius="md"
        boxShadow="lg"
        padding={"20px"}
        mt={"80px"}
      >
        <Box flex="1" mr={{ base: 0, md: 6 }} mb={{ base: 6, md: 0 }} textAlign={{ base: 'center', md: 'left' }}>
          <Heading as="h1" size="2xl" mb={4} color="green.700">
            Fresh Avocados, Delivered to You
          </Heading>
          <Text fontSize="lg" color="green.600">
            Experience the convenience and joy of fresh, sustainably sourced avocados delivered straight to your doorstep. Your journey to avocado heaven starts here.
          </Text>
          <Button
            as={Link}
            to="/about"
            mt={6}
            colorScheme="green"
            size="lg"
            _hover={{ bg: "green.600" }}
          >
            Learn More
          </Button>
        </Box>
        <Image
          src="https://cdn.pixabay.com/photo/2017/05/23/22/36/vegetables-2338824_1280.jpg" // Replace this with your image path
          alt="Hero image of fresh avocados"
          borderRadius="md"
          boxShadow="md"
          flex="1"
          maxH="400px"
          objectFit="cover"
        />
      </Flex>

      <Divider my={10} />

      {/* Features Section */}
      <VStack spacing={8} py={10} textAlign="center">
        <Heading as="h2" size="xl" color="green.700">
          Why Choose Us?
        </Heading>
        <HStack spacing={10} wrap="wrap" justify="center">
          <Box textAlign="center" maxW="300px">
            <Image
          src="https://cdn.pixabay.com/photo/2024/08/19/00/14/avocado-8979119_1280.jpg" // Replace this with your image path
          alt="Fresh avocados"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"
            />
            <Heading as="h3" size="md" color="green.700" mb={2}>
              Freshness Guaranteed
            </Heading>
            <Text color="green.600">
              Handpicked and delivered at peak ripeness for unparalleled quality.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/03/27/08/45/current-4972878_1280.jpg" // Replace this with your image path
              alt="Sustainability practices"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"
            />
            <Heading as="h3" size="md" color="green.700" mb={2}>
              Eco-Friendly Practices
            </Heading>
            <Text color="green.600">
              Supporting sustainable farming and reducing waste.
            </Text>
          </Box>
          <Box textAlign="center" maxW="300px">
            <Image
              src="https://cdn.pixabay.com/photo/2020/12/06/22/51/ship-5810249_1280.jpg" // Replace this with your image path
              alt="Doorstep delivery"
              borderRadius="full"
              boxSize="150px"
              mb={4}
              mx="auto"
            />
            <Heading as="h3" size="md" color="green.700" mb={2}>
              Convenient Delivery
            </Heading>
            <Text color="green.600">
              Reliable service to bring avocados directly to your home.
            </Text>
          </Box>
        </HStack>
      </VStack>

      <Divider my={10} />

      {/* Testimonials Section */}
      <VStack spacing={8} py={10} bg="green.50" borderRadius="md" boxShadow="lg">
        <Heading as="h2" size="xl" color="green.700">
          What Our Customers Say
        </Heading>
        <HStack spacing={10} wrap="wrap" justify="center">
          <Box maxW="300px" textAlign="center">
            <Text color="green.600" fontStyle="italic">
              "The best avocados I've ever had! So fresh and creamy."
            </Text>
            <Text fontWeight="bold" mt={2} color="green.700">
              - Sarah L.
            </Text>
          </Box>
          <Box maxW="300px" textAlign="center">
            <Text color="green.600" fontStyle="italic">
              "I love their eco-friendly approach. Highly recommend!"
            </Text>
            <Text fontWeight="bold" mt={2} color="green.700">
              - Josh T.
            </Text>
          </Box>
          <Box maxW="300px" textAlign="center">
            <Text color="green.600" fontStyle="italic">
              "Super convenient and always reliable. My family loves it!"
            </Text>
            <Text fontWeight="bold" mt={2} color="green.700">
              - Emily R.
            </Text>
          </Box>
        </HStack>
      </VStack>

      <Divider my={10} />

      {/* Call to Action */}
      <Box textAlign="center" py={10}>
        <Heading as="h2" size="xl" mb={4} color="green.700">
          Ready to Get Started?
        </Heading>
        <Text fontSize="lg" color="green.600" maxW="800px" mx="auto" mb={6}>
          Join our avocado-loving community today. Sign up and start enjoying the freshest avocados delivered to your door.
        </Text>
        <Button
          as={Link}
          to="/contact"
          colorScheme="green"
          size="lg"
          px={6}
          py={3}
          borderRadius="md"
          bg="green.500"
          color="white"
          _hover={{ bg: "green.600" }}
        >
          Get in Touch
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;
